.splash-btn {
    background-color: #363634;
    color: white !important;
    position: absolute;
    right: 20%;
    bottom: 17%;
    line-height: 1.2 !important;
}


/* .splash-bg{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/image/hide.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100vh;
} */


.splash-img{
    width: 100% !important;
    height: 96vh !important;
    margin-bottom: 2rem;
}

.splash-footer{
    position: absolute;
    bottom: 0px;
    left: calc(50% - 90px);
}

.view-directory-btn {
    background-color: #363634;
    color: white !important;
    padding: 10px 30px;
    font-weight: 600;
    /* line-height: 1.2 !important; */
}


.splash-canvas .offcanvas-bottom {
    height: 70vh !important;
    border-radius: 15px 15px 0px 0px;
    border-top: 5px solid #f7904c;
}


.splash-canvas .offcanvas-header .close-splash-btn {
    position: relative;
    top: -70px !important;
    right: 45%;
    font-size: 30px;
    z-index: 99;
    color: #fff;
}

.movingline h5::after {
    position: absolute;
    content: "";
    width: 150px;
    height: 5px;
    border-left: 80px solid #e0b03f;
    border-right: 80px solid #f7904c;
    background-repeat: no-repeat;
    left: 0;
    top: 60px;
    animation: animationborder 2s linear infinite;
    z-index: 99;
}


.footer-btn {
    background-color: #363634;
    border: 1px solid white;
    color: white !important;
    /* padding: 10px 30px; */
    /* font-weight: 600; */
}




.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    width: 80% !important;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.swiper-text{
    position: absolute;
    width: 99%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px 0px;
    right: 0px;
    bottom: 25px;
}



.form-canvas .offcanvas-bottom {
    height: 90vh !important;
    border-radius: 15px 15px 0px 0px;
    border-top: 5px solid #f7904c;
}



.card-canvas .offcanvas-bottom {
    height: 80vh !important;
    border-radius: 15px 15px 0px 0px;
    border-top: 5px solid #f7904c;
}

.form-canvas .offcanvas-header .close-card-btn {
    position: relative;
    top: -70px !important;
    right: 45%;
    font-size: 30px;
    z-index: 99;
}


.card-canvas .offcanvas-header{
    padding: 10px 16px 0px 16px;
}

.card-canvas .offcanvas-header .offcanvas-title {
    /* margin-bottom: 0; */
    line-height: 1.2;
}

.card-canvas .offcanvas-bottom .offcanvas-body a {
    color: #363636 !important;
}


.card-canvas .offcanvas-header .close-card-btn {
    position: relative;
    top: -70px !important;
    right: 45%;
    font-size: 30px;
    z-index: 99;
    color: #fff;
    cursor: pointer;
}