.stats-page .top-btn {
  padding: 5px 10px;
  background: #f7904c;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  font-weight: 600;
  display: inline-block;
}
.stats-page .top-btn.active {
  background: #ff6600;
}

.stats-page .scroll-card-box {
  display: flex;
  overflow-x: auto;
}
.stats-page .scroll-card-box .scroll-card {
  display: flex;
  align-items: center;
  border: 1px solid #f7904c;
  min-width: 80%;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 10px;
}
.stats-page .scroll-card-box .scroll-card:last-child {
  margin-right: 0;
}
.stats-page .scroll-card-box .scroll-card > div{
  width: 75%;
}
.stats-page .scroll-card-box .scroll-card-icon {
  width: 25% !important;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7904c;
  margin-right: 1rem;
}
.stats-page .scroll-card-box .scroll-card-icon svg {
  height: 40px;
  width: 40px;
  color: #fff;
}
.stats-page .scroll-card-box h5 {
  margin-bottom: 0;
}
.stats-page .scroll-card-box p {
  font-size: 1.2rem !important;
}

.stats-page .mid-card {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #f3f3f3;
}
.stats-page .mid-card b {
  background: #f7904c;
  color: #fff;
  font-size: 1.3rem;
  padding: 10px;
  width: 100%;
  display: block;
}
.stats-page .mid-card .mid-card-content {
  padding: 10px;
}
.stats-page .graph-card strong {
  padding: 10px;
}
.stats-page .bottom-border {
  border-bottom: 4px solid #f7904c !important;
  border-radius: 5px !important;
}

.visitor-list-page .visitor-list-card {
  border: 1px solid #f7904c;
  border-top: 4px solid #f7904c;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 1rem; */
}
.visitor-list-page .visitor-list-card p {
  line-height: 1.2;
}
.visitor-list-page .visitor-list-card .visitor-list-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 10px;
}

.visitor-list-page .visitor-list-search-box {
  display: flex;
}

.visitor-list-page .visitor-list-search-box input {
  border-radius: 5px 0 0 5px !important;
}
.visitor-list-page .visitor-list-search-box span {
  padding: 5px 15px;
  background: #f7904c;
  color: #fff;
  border-radius: 0 5px 5px 0;
  outline: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}

.start-page {
  position: relative;
}
.start-page img{
    height: 100vh;
}
.start-page .enter-pin-box {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
}

.start-page .enter-pin-box input{
    padding: 10px !important;
}