.gallery {
  width: 98%;
  height: auto;
  display: flex;
  margin: auto;
  margin-top: 50px;
  flex-direction: column;
  margin-bottom: 50px;
}
.heading {
  display: flex;
  margin: auto;
}
.heading p {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 30px;
  font-family: "Exo", sans-serif;
}
.photo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  overflow: hidden;
}
.photo img {
  width: 100%;
  height: 250px;

  transition-duration: 1.5s;
  /* filter: brightness(0.6); */
}
@media screen and (min-width: 151px) and (max-width: 768px) {
  .photo {
    display: grid !important;
    grid-template-columns: 1fr !important;
    gap: 10px !important;
  }
}
.zoom {
  overflow: hidden;
  /* border-radius: 10px; */
  box-shadow: 2px 2px 4px -1px rgb(0 0 0 / 25%);
}
.zoom:hover img {
  transform: scale(1.25);
  transition-duration: 1.5s;
  filter: brightness(1);
}
.hide-logo img {
  width: 260px;
  /* height: 87px; */
  margin-left: 0px;
}
.video-section iframe {
  width: 100%;
  height: 500px;
}
