.parallax-section {
    width: 100%;
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets//banner/Parallax.jpg);
    background-attachment: fixed;
    background-size: cover;

}

/* Seminar Page */

.parallax-button {
    width: fit-content !important;
    padding: 5px 8px;
    margin: 10px;
}

.parallax-button a {
    color: rgb(255, 255, 255);
}


.parallax-section .d-flex {
    align-items: center;
    justify-content: center;

}

/* @media (min-width:992px) { */
.parallax h2 {
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 10px;
}

.parallax h2::after {
    position: absolute;
    content: "";
    width: 100px !important;
    height: 5px;
    border-left: 110px solid #f7904c;
    border-right: 110px solid #f7904c;
    background-repeat: no-repeat;
    left: -20%;
    /* transform: translateX(-50%); */
    top: inherit;
    bottom: 0;
    animation: animationborder 2s linear infinite;
}



@media (max-width:568px) {
    .parallax h2::after {
        border-left: 80px solid #f7904c;
        border-right: 80px solid #f7904c;
        left: -100% !important;
    }
}