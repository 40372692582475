.sponsors-box {
  width: 100%;
  display: flex;
  overflow-x: auto;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 10px;
}
.sponsors-box .sponsors-card {
  min-width: 150px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1rem;
  padding: 5px;
  border: 1px solid #f7904c;
  border-radius: 5px;
}
.sponsors-box .sponsors-card:last-child {
  margin-right: 0;
}
.sponsors-box .sponsors-card p {
  font-size: 0.8rem;
  font-weight: 500;
}
.sponsors-box .sponsors-card .image {
  border-radius: 3px;
  width: 100%;
  overflow: hidden;
}
.sponsors-box .sponsors-card .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.directory-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  border-bottom: 2px solid #f7904c;
  border-radius: 5px;
}

.directory-card .image img {
  height: 300px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  object-fit: contain;
  cursor: pointer;
}
.directory-card .booth-no {
  position: absolute;
  top: 0;
  left: 10px;
  background: #f7904c;
  color: #fff !important;
  font-weight: 500;
  /* border-radius: 5px 5px 0 0; */
  margin-top: 10px;
  width: fit-content;
}
.directory-card .view-btn {
  color: #000 !important;
  padding: 5px 8px;
  width: fit-content;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  cursor: pointer;
}
.directory-card .card-links {
  padding: 10px 5px;
  border-top: 1px solid #e3e3e3;
}
.directory-card .bi-geo-alt-fill {
  color: #f7904c;
  font-size: 1.2rem !important;
  margin-right: 5px;
}
.directory-card .card-links a i {
  display: inline-block;
  /* padding: 10px; */
  /* background: #f7904c; */
  color: #f7904c;
  /* border-radius: 50%; */
  margin-left: 30px;
  font-size: 1.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer-links a {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}
.drawer-links a i {
  display: inline-block;
  /* width: 30px;
  height: 30px; */
  padding: 10px;
  background: #f7904c;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.directory-image-box {
  width: 100%;
  display: flex;
  overflow-x: auto;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  gap: 1rem;
}
.directory-image-box img {
  border-radius: 5px;
  width: 40%;
}
.qr-code-page {
  height: 90vh;
  width: 100%;
  /* background: #f3f3f3; */
  overflow: hidden;
}

.qr-code-box {
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem;
  display: none;
  /* overflow: hidden; */
}
.qr-code-page .qr-code-img {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code-page .qr-code-box .divider {
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qr-code-page .qr-code-box .divider span {
  width: 90%;
  height: 1px;
  border-top: 1px dashed #e3e3e3;
  display: inline-block;
}
.qr-code-page .qr-code-box .divider::before,
.qr-code-page .qr-code-box .divider::after {
  content: "";
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e3e3e3;
}
.qr-code-page .qr-code-box .divider::before {
  left: -30px;
}
.qr-code-page .qr-code-box .divider::after {
  right: -30px;
}

.qr-code-page .qr-code-box .qr-content .qr-box {
  padding: 10px 0;
  border-bottom: 1px solid #e3e3e3;
}
.qr-code-page .qr-code-box .qr-content .qr-box:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.qr-code-page .qr-code-box .qr-content .qr-box p {
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
}

.qr-code-page .qr-code-box .qr-content .qr-box p a {
  color: #000;
}
.qr-code-page .qr-code-box .qr-content .qr-box span {
  color: #848484;
}

.id-card-holder {
  width: 100%;
  padding: 4px;
  margin: 0 auto;
  background-color: #f7904c;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.id-card-holder:after {
  content: "";
  width: 7px;
  display: block;
  background-color: #f7904c;
  height: 100px;
  position: absolute;
  top: 105px;
  left: 0;
  border-radius: 0 5px 5px 0;
}
.id-card-holder:before {
  content: "";
  width: 7px;
  display: block;
  background-color: #f7904c;
  height: 100px;
  position: absolute;
  top: 105px;
  /* left: 222px; */
  right: 0;
  border-radius: 5px 0 0 5px;
}
.id-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 0 1.5px 0px #b9b9b9;
}
.id-card img {
  margin: 0 auto;
}
.header img {
  width: 80%;
  margin-top: 15px;
}
.id-card-holder .photo img {
  width: 80% !important;
  height: auto !important;
  /* margin: 15px auto;
  border: 1px solid #f7904c;
  padding: 5px; */
  /* border-radius: 5px; */
}
.id-card-holder h2 {
  /* font-size: 15px; */
  margin: 5px 0;
}
.id-card-holder h3 {
  /* font-size: 12px; */
  margin: 2.5px 0;
  /* font-weight: 300; */
}
.id-card-holder .divider {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e3e3e3;
  display: inline-block;
}
.id-card-holder .qr-content {
  text-align: left;
}

.id-card-holder .qr-content .qr-box {
  /* padding: 10px 0;
  border-bottom: 1px solid #e3e3e3; */
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
}
.id-card-holder .qr-content .qr-box:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.id-card-holder .qr-content .qr-box p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #000;
}

.id-card-holder .qr-content .qr-box p a {
  color: #000;
}
.id-card-holder .qr-content .qr-box span {
  color: #848484;
}

.qr-code img {
  width: 50px;
}
/* .id-card-holder p {
    font-size: 5px;
    margin: 2px;
} */
.id-card-hook {
  background-color: #f7904c;
  width: 70px;
  margin: 0 auto;
  height: 15px;
  border-radius: 5px 5px 0 0;
}
.id-card-hook:after {
  content: "";
  background-color: #fff;
  width: 47px;
  height: 6px;
  display: block;
  margin: 0px auto;
  position: relative;
  top: 6px;
  border-radius: 4px;
}

.bottom-navbar {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
  background: #fff;
}
.bottom-navbar ul {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}
.bottom-navbar ul li {
  /* width: 25%; */
}
.bottom-navbar ul li a {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  font-size: 0.5rem;
  color: #000 !important;
}

.bottom-navbar ul li a svg {
  font-size: 1.5rem;
  color: #f7904c !important;
}

.seminar-card {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #000;
  margin-top: 1rem;
}
.seminar-card h4 {
  color: #f7904c;
}
.seminar-card .seminar-box {
  display: flex;
  border-bottom: 0.5px solid #e3e3e3;
  padding: 5px 0;
}
.seminar-card .seminar-box:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.seminar-card .seminar-box span {
  font-weight: 500;
  margin-right: 10px;
}
.seminar-card .seminar-box p {
  font-size: 1.1rem;
}
.seminar-card .seminar-box p span {
  font-weight: 500 !important;
}
.seminar-calendar-button {
  bottom: 6rem;
}
.seminar-button.position-static {
  transform: translate(0) !important;
}
.dashboard-page .lastlogowidth {
  width: 100% !important;
  object-fit: contain !important;
}
.directory-input {
  border: 1px solid #e3e3e3;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  line-height: 2;
}
.seminar-page h4 {
  color: #f7904c;
  margin-top: 1rem;
  margin-bottom: 10px !important;
}
